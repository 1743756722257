import { writable } from 'svelte/store'
import netlifyIdentity from 'netlify-identity-widget'
export let user = writable()

netlifyIdentity.on('init', (u) => {
  if (u) user.set(u)
  else login()
})

netlifyIdentity.init()

export function login() {
  netlifyIdentity.open()
  // window.location.replace('/')
}

export function logout() {
  netlifyIdentity.logout()
  user.set(undefined)
  window.location.replace('/')
}

export async function getToken() {
  return await netlifyIdentity.refresh()
}

netlifyIdentity.on('login', (u) => user.set(u))
