<script>
  import axios from 'axios'
  import { apiUrl } from './const'
  import { onMount, onDestroy, afterUpdate, tick } from 'svelte'
  import { navigate } from 'svelte-routing'
  import { user, getToken } from './identity'
  import { articles } from './stores/articleStore'

  export let id

  let article

  const url = `${apiUrl}/articles`

  async function update(fields) {
    // update locally and then make the network call
    Object.assign(article.fields, fields)
    try {
      const result = await axios.patch(
        url,
        [
          {
            id,
            fields,
          },
        ],
        {
          headers: { Authorization: `Bearer ${await getToken()}` },
        }
      )
      article = result.data[0]
    } catch (error) {
      console.error(error)
    }
  }

  function updateArchiveStatus(isArchived) {
    update({ archived: isArchived })
    navigate('/')
  }

  function setScrollPosition(percentage = 0) {
    // need to wait for images to load... need a better way to do this
    setTimeout(() => {
      if (percentage === 0) return
      const contentElement = document.querySelector('.content')

      const offsetTop = contentElement.offsetTop
      const height = contentElement.scrollHeight
      const position = percentage * (height - offsetTop) + offsetTop

      window.scrollTo(0, position)
    }, 500)
  }

  function getProgress() {
    const contentElement = document.querySelector('.content')

    let scrollTop = document.documentElement.scrollTop

    const offsetTop = contentElement.offsetTop
    const height = contentElement.scrollHeight

    let percentage = (scrollTop - offsetTop) / (height - offsetTop)
    if (percentage <= 0) return 0
    if (percentage >= 100) return 100
    return percentage
  }

  function updateReadPercent() {
    update({ read_percent: getProgress() })
  }

  let interval

  onMount(async () => {
    interval = setInterval(updateReadPercent, 10000)

    if (!article) {
      if (!$user) {
        navigate('/')
      }
      await fetch(`${url}?id=${id}`, {
        headers: { Authorization: `Bearer ${await getToken()}` },
      })
        .then(r => r.json())
        .then(async data => {
          article = data
          setScrollPosition(article.fields.read_percent)
        })
        .catch(e => console.error(e))
    }
  })

  onDestroy(() => {
    clearInterval(interval)
    updateReadPercent()
  })
</script>

<style>
  .content * {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .content p {
    margin-bottom: 1em;
  }

  img {
    max-width: 100%;
  }
</style>

<section class="section">
  <div class="container">
    {#if article}
      <h1 class="title is-1">{article.fields.title}</h1>
      {#if article.fields.lead_image_url !== 'null'}
        <img src={article.fields.lead_image_url} alt="lead" />
      {/if}
      <div class="content">
        {@html article.fields.content}
      </div>
      {#if article.fields.archived}
        <button
          class="button is-dark"
          on:click={() => updateArchiveStatus(false)}>
          UnArchive
        </button>
      {:else}
        <button
          class="button is-dark"
          on:click={() => updateArchiveStatus(true)}>
          Archive
        </button>
      {/if}
    {/if}
  </div>
</section>
