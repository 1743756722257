<script>
  import Home from './Home.svelte'
  import Article from './Article.svelte'
  import NavLink from './NavLink.svelte'
  import { Router, Route } from 'svelte-routing'
  import { user, logout, login } from './identity.js'

  export let url = ''
  let isMenuOpen = false
</script>

<style>
  * {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
</style>

<div class="container">
  <Router {url}>
    <nav class="navbar">
      <div class="navbar-brand">
        <NavLink to="/">Home</NavLink>
        <span
          class:is-active={isMenuOpen}
          on:click={() => (isMenuOpen = !isMenuOpen)}
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false">
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>
      <div class="navbar-menu" class:is-active={isMenuOpen}>
        <NavLink to="/archive">Archive</NavLink>
        <div class="navbar-end">
          <span
            class="navbar-item"
            style="cursor:pointer;"
            role="button"
            on:click={$user ? logout : login}>
            Log {$user ? 'Out' : 'In'}
          </span>
        </div>
      </div>
    </nav>
    <div>
      <Route path="article/:id" component={Article} />
      <Route path="archive">
        <Home archive={true} />
      </Route>
      <Route path="/">
        <Home archive={false} />
      </Route>
    </div>
  </Router>
</div>
