<script>
  import ArticleList from './ArticleList.svelte'
  export let archive
</script>

<style>
  h1 {
    text-align: center;
  }
</style>

<main>
  <h1 class="title is-1">Article List</h1>
  <ArticleList {archive} />
</main>
