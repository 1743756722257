<script>
  import { onMount } from 'svelte'
  import { Link } from 'svelte-routing'
  import { derived } from 'svelte/store'
  import { navigate } from 'svelte-routing'
  import { articles, getArticles } from './stores/articleStore'
  export let archive = false

  const filtered = derived(articles, $a => {
    return [...$a]
      .filter(article => !!article.fields.archived == archive)
      .sort((a, b) =>
        a._rawJson.createdTime > b._rawJson.createdTime ? -1 : 1
      )
  })
  onMount(() => {
    // only make the call if we don't have the articles
    if ($filtered.length === 0) getArticles()
  })
</script>

{#each $filtered as article}
  <div class="box">
    <div class="content">
      <p>
        <Link to={`article/${article.id}`}>
          <strong>{article.fields.title}</strong>
        </Link>
        {#if article.fields.author}
          <br />
          <span>by {article.fields.author}</span>
        {/if}
        {#if article.fields.excerpt}
          <br />
          <span>{article.fields.excerpt}</span>
        {/if}
      </p>
    </div>
  </div>
{/each}
