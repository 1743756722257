import netlifyIdentity from 'netlify-identity-widget'
import { writable } from 'svelte/store'
import { apiUrl } from '../const'
import { user, getToken, login } from '../identity'
const url = `${apiUrl}/articles`
export let articles = writable([])

let loggedInUser

user.subscribe((u) => {
  loggedInUser = u
})

netlifyIdentity.on('login', () => {
  netlifyIdentity.close()
  getArticles()
})

export async function getArticles() {
  if (!loggedInUser) {
    login()
    return
  }
  await fetch(url, {
    headers: { Authorization: `Bearer ${await getToken()}` },
  })
    .then((r) => r.json())
    .then((data) => {
      articles.set(data)
    })
    .catch((e) => console.error(e))
}
